<template>
  <div>
    <!-- Start Role  -->
    <b-modal
      v-model="modal.isShow"
      :title="`${modal.id ? $t('TABLE.UPDATE') : $t('TABLE.CREATE')} ${$t(
        'MENU.ROLE'
      )}`"
      @ok="submitModal"
      size="lg"
    >
      <b-form @submit.stop.prevent="createOrUpdateRole" ref="form">
        <b-form-group
          :label="`${$t('TABLE.NAME')}:`"
          :state="$v.modal.role.required"
        >
          <b-form-input
            :placeholder="$t('TABLE.NAME')"
            v-model="$v.modal.role.$model"
            :state="validateState('role')"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ $t("MODAL.FIELD_REQUIRED") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('ROLE.ACCESS')}:`">
          <v-tree
            ref="tree"
            :data="modal.access"
            :multiple="true"
            :tpl="tpl"
            :halfcheck="true"
            :allowGetParentNode="true"
            class="tree-view"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- Start Header  -->
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 py-3 page-title">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">{{
            $t("MENU.ROLE")
          }}</span>
        </h3>
        <b-button
          v-if="getAccess.includes('admin_access--CREATE')"
          variant="primary"
          size="sm"
          @click="showModal()"
          >{{ $t("TABLE.CREATE") }}</b-button
        >
      </div>
    </div>

    <!-- Start List  -->
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pb-0">
        <b-table
          class="bg-white"
          striped
          hover
          :fields="fields"
          :items="items"
          responsive
        >
          <!-- Set No Data when none of item -->
          <template #bottom-row="data" v-if="items.length === 0">
            <b-td :colspan="fields.length" class="text-center">{{
              $t("TABLE.NO_DATA")
            }}</b-td>
          </template>

          <!-- A virtual column -->
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(action)="data">
            <b-button
              v-if="getAccess.includes('admin_access--CREATE')"
              size="sm"
              class="mr-3"
              variant="primary"
              @click="showModal(data.item, 'copy')"
            >
              {{ $t("TABLE.COPY_ACCESS") }}
            </b-button>
            <b-button
              v-if="getAccess.includes('admin_access--UPDATE')"
              size="sm"
              class="mr-3"
              variant="info"
              @click="showModal(data.item)"
            >
              {{ $t("TABLE.UPDATE") }}
            </b-button>
            <b-button
              v-if="getAccess.includes('admin_access--DELETE')"
              size="sm"
              class="mr-3"
              variant="danger"
              @click="deleteRole(data.item)"
            >
              {{ $t("TABLE.DELETE") }}
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
    <!-- End List  -->
    <!-- End Role  -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  GET_ROLE,
  GET_ROLE_DETAIL,
  CREATE_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE
} from "@/core/services/store/role.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import { mapGetters } from "vuex";
import globalMixin from "@/core/plugins/mixins.js";

export default {
  mixins: [validationMixin, globalMixin],
  computed: {
    ...mapGetters(["getAccess"])
  },
  data() {
    return {
      fields: [
        {
          key: "index",
          label: this.$t("TABLE.INDEX"),
          thClass: "text-right",
          tdClass: "text-right align-middle"
        },
        {
          key: "role",
          label: this.$t("MENU.ROLE"),
          thClass: "text-left",
          tdClass: "text-left align-middle"
        },
        {
          key: "action",
          label: this.$t("TABLE.ACTION"),
          thClass: "text-center",
          tdClass: "text-center align-middle action-column"
        }
      ],
      items: [],
      modal: {
        isShow: false,
        id: 0,
        role: "",
        access: []
      },
      treeData: [
        // TODO: Open for now
        // {
        //   id: "dashboard--READ",
        //   title: "Dashboard",
        //   resource: "dashboard",
        //   access: "READ",
        //   isAccess: true
        // },
        // TODO: Open for now
        {
          id: "recent_transaction",
          title: this.$t("MENU.RECENT_TRANSACTION"),
          expanded: true,
          checked: false,
          children: [
            {
              id: "recent_transaction--READ",
              isAccess: true,
              checked: false,
              resource: "recent_transaction",
              access: "READ",
              title: this.$t("TABLE.READ")
            }
          ]
        },
        {
          id: "deposit",
          title: this.$t("MENU.DEPOSIT"),
          expanded: true,
          checked: false,
          children: [
            {
              id: "deposit--READ",
              isAccess: true,
              checked: false,
              resource: "deposit",
              access: "READ",
              title: this.$t("TABLE.READ")
            }
          ]
        },
        {
          id: "withdraw",
          title: this.$t("MENU.WITHDRAWAL"),
          expanded: true,
          checked: false,
          children: [
            {
              id: "withdraw--READ",
              isAccess: true,
              checked: false,
              resource: "withdraw",
              access: "READ",
              title: this.$t("TABLE.READ")
            },
            {
              id: "withdraw--CREATE",
              isAccess: true,
              checked: false,
              resource: "withdraw",
              access: "CREATE",
              title: this.$t("TABLE.CREATE")
            },
            {
              id: "withdraw--UPDATE",
              isAccess: true,
              checked: false,
              resource: "withdraw",
              access: "UPDATE",
              title: this.$t("TABLE.UPDATE")
            }
          ]
        },
        {
          id: "tier",
          resource: "tier",
          title: this.$t("MENU.CLIENT_LIST"),
          expanded: true,
          checked: false,
          children: [
            {
              id: "tier--READ",
              isAccess: true,
              checked: false,
              resource: "tier",
              access: "READ",
              title: this.$t("TABLE.READ")
            },
            {
              id: "tier--CREATE",
              isAccess: true,
              checked: false,
              resource: "tier",
              access: "CREATE",
              title: this.$t("TABLE.CREATE")
            },
            {
              id: "tier--UPDATE",
              isAccess: true,
              checked: false,
              resource: "tier",
              access: "UPDATE",
              title: this.$t("TABLE.UPDATE")
            },
            {
              id: "tier--DELETE",
              isAccess: true,
              checked: false,
              resource: "tier",
              access: "DELETE",
              title: this.$t("TABLE.DELETE")
            }
          ]
        },
        {
          id: "operator",
          resource: "operator",
          title: this.$t("MENU.OPERATOR_LIST"),
          expanded: true,
          checked: false,
          children: [
            {
              id: "operator--READ",
              isAccess: true,
              checked: false,
              resource: "operator",
              access: "READ",
              title: this.$t("TABLE.READ")
            },
            {
              id: "operator--CREATE",
              isAccess: true,
              checked: false,
              resource: "operator",
              access: "CREATE",
              title: this.$t("TABLE.CREATE")
            },
            {
              id: "operator--UPDATE",
              isAccess: true,
              checked: false,
              resource: "operator",
              access: "UPDATE",
              title: this.$t("TABLE.UPDATE")
            },
            {
              id: "operator--DELETE",
              isAccess: true,
              checked: false,
              resource: "operator",
              access: "DELETE",
              title: this.$t("TABLE.DELETE")
            }
          ]
        },
        {
          id: "admin_access",
          title: this.$t("MENU.ROLE"),
          expanded: true,
          checked: false,
          children: [
            {
              id: "admin_access--READ",
              isAccess: true,
              checked: false,
              resource: "admin_access",
              access: "READ",
              title: this.$t("TABLE.READ")
            },
            {
              id: "admin_access--CREATE",
              isAccess: true,
              checked: false,
              resource: "admin_access",
              access: "CREATE",
              title: this.$t("TABLE.CREATE")
            },
            {
              id: "admin_access--UPDATE",
              isAccess: true,
              checked: false,
              resource: "admin_access",
              access: "UPDATE",
              title: this.$t("TABLE.UPDATE")
            },
            {
              id: "admin_access--DELETE",
              isAccess: true,
              checked: false,
              resource: "admin_access",
              access: "DELETE",
              title: this.$t("TABLE.DELETE")
            }
          ]
        },
        {
          id: "currency",
          resource: "currency",
          title: this.$t("MENU.CURRENCY_LIST"),
          expanded: true,
          checked: false,
          children: [
            {
              id: "currency--READ",
              isAccess: true,
              checked: false,
              resource: "currency",
              access: "READ",
              title: this.$t("TABLE.READ")
            },
            {
              id: "currency--UPDATE",
              isAccess: true,
              checked: false,
              resource: "currency",
              access: "UPDATE",
              title: this.$t("TABLE.UPDATE")
            }
          ]
        },
        {
          id: "setting",
          title: this.$t("MENU.SETTING"),
          expanded: true,
          checked: false,
          children: [
            {
              id: "setting--READ",
              isAccess: true,
              checked: false,
              resource: "setting",
              access: "READ",
              title: this.$t("TABLE.READ")
            },
            {
              id: "setting--UPDATE",
              isAccess: true,
              checked: false,
              resource: "setting",
              access: "UPDATE",
              title: this.$t("TABLE.UPDATE")
            }
          ]
        }
      ]
    };
  },
  validations: {
    modal: {
      role: {
        required
      }
    }
  },
  mounted() {
    this.getRole();
  },
  methods: {
    tpl(...args) {
      let { 0: node } = args;
      let titleClass = node.selected
        ? "node-title node-selected"
        : "node-title";
      if (node.searched) titleClass += " node-searched";
      return (
        <span>
          <span
            class={titleClass}
            domPropsInnerHTML={node.title}
            onClick={() => {
              this.$refs.tree.nodeSelected(node);
            }}
          ></span>
        </span>
      );
    },
    addCheckData(dt) {
      const $self = this;
      return dt.map(function (e) {
        if (e.isAccess) {
          const found = $self.modal.resources.find(function (o) {
            return o.resource == e.resource && o.access.includes(e.access);
          });

          if (found) {
            return { ...e, checked: true };
          } else {
            return e;
          }
        } else {
          return { ...e, children: $self.addCheckData(e.children) };
        }
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.modal[name];
      return $dirty ? !$error : null;
    },
    async showModal(dt, copy = false) {
      this.modal.isShow = true;
      this.modal.id = dt ? dt.id : 0;
      this.modal.role = dt ? dt.role : "";
      this.modal.access = [];

      const selectedTree = JSON.parse(JSON.stringify(this.treeData));
      if (dt) {
        await this.getRoleDetail();
        this.modal.access = this.addCheckData(selectedTree);
      } else {
        this.modal.access = selectedTree;
      }

      if (copy) {
        this.modal.id = 0;
        this.modal.role = "";
      }

      this.$v.$reset();
    },
    submitModal(modalEvent) {
      // Prevent modal from closing
      modalEvent.preventDefault();
      // Trigger submit handler
      this.createOrUpdateRole();
    },
    getRole() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(GET_ROLE)
        .then((e) => {
          this.items = e;
        })
        .catch((err) => {
          Swal.fire(this.translateErrorFromCode(err), "", "error");
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    async getRoleDetail() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      const response = await this.$store.dispatch(
        GET_ROLE_DETAIL,
        this.modal.role
      );

      if (response) {
        this.modal.resources = response.permission;
      } else {
        this.modal.resources = [];
      }
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    },
    createOrUpdateRole() {
      // Exit when the form isn't valid
      this.$v.modal.$touch();
      if (this.$v.modal.$anyError) {
        return;
      }

      const resources = this.$refs.tree
        .getCheckedNodes()
        .filter(function (e) {
          return !!e.isAccess;
        })
        .map(function (e) {
          return {
            resource: e.resource,
            access: e.access
          };
        });

      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(
          this.modal.id ? UPDATE_ROLE : CREATE_ROLE,
          this.modal.id
            ? {
                id: this.modal.id,
                role: this.modal.role,
                resources
              }
            : {
                role: this.modal.role,
                resources
              }
        )
        .then(() => {
          this.getRole();
          Swal.fire(
            this.modal.id
              ? this.$t("MODAL.UPDATE_SUCESSFULL")
              : this.$t("MODAL.CREATE_SUCESSFULL"),
            "",
            "success"
          );
          this.modal.isShow = false;
        })
        .catch((err) => {
          Swal.fire(this.translateErrorFromCode(err), "", "error");
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    deleteRole(data) {
      Swal.fire({
        title: `${this.$t("MODAL.DELETE_CONFIRMATION")} "${data.role}" ?`,
        showCancelButton: true,
        confirmButtonText: "Confirm"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
          this.$store
            .dispatch(DELETE_ROLE, data.role)
            .then(() => {
              this.getRole();
              Swal.fire(this.$t("MODAL.DELETE_SUCESSFULL"), "", "success");
            })
            .catch((err) => {
              Swal.fire(this.translateErrorFromCode(err), "", "error");
            })
            .then(() => {
              this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.page-title {
  min-height: auto !important;
}

::v-deep .action-column {
  min-width: 175px;
}

.tree-view {
  margin-left: 13px;
}
</style>
